<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="template-table-fields-modal-label">{{ table_title }}</h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>
            <div v-if="table_rows.length > 0">
                <div v-for="row in getVisibleRows()" :key="row.row">
                    <div v-if="row.row == 0" class="row">
                        <div v-for="heading in table_headings" :key="heading" class="col">
                            <label>{{ heading }}</label>
                        </div>
                        <div v-if="table_rows.length > 2" class="col-1">
                        </div>
                    </div>
                    <div v-else class="row">
                        <div v-for="item in row.fields" :key="item.id" class="col">
                            <template-field
                                :artwork_editor_component="$parent"
                                :data="item"
                                :profile_id="$parent.profile_id"
                                :readonly="$parent.isProfileMissing()"
                                :reload="count"
                            ></template-field>
                        </div>
                        <div v-if="table_rows.length > 2" class="col-1">
                            <button
                                v-if="row.row > 1"
                                type="button"
                                class="btn btn-secondary big-icon btn-block"
                                @click="deleteRow(row)"
                                style="width: 100%"
                                v-b-tooltip.hover.left
                                title="Delete row"
                            >
                                <i class="far fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="num_visible_rows < (table_rows.length - 1)">
                    <button type="button" class="btn btn-secondary" @click="addRow()">
                        <i class="far fa-plus mr-1"></i> Add row
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="updateArtwork()">
                <i class="far fa-eye mr-1"></i> Update preview
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['artwork'],
        data () {
            return {
                errors: false,
                table_title: null,
                table_headings: [],
                table_rows: [],
                count: 0,
                num_visible_rows: 1
            }
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            reset: function (data) {
                this.count++;
                this.errors = false;
                this.table_title = data.title;
                this.table_headings = [];
                this.table_rows = [];
                this.num_visible_rows = 1;

                // Build table.
                let numRows = 0;
                data.fields.forEach((item) => {
                    let titleParts = item.title.split('_');
                    let heading = titleParts[1];
                    if (this.table_headings.indexOf(heading) < 0) {
                        this.table_headings.push(heading);
                    }
                    let rowNum = parseInt(titleParts[2]);
                    if (rowNum > numRows) {
                        numRows = rowNum;
                    }
                });
                if ((this.table_headings.length == 0) || (numRows == 0)) {
                    this.errors = 'No table fields found.'

                    return null;
                }
                let rows = [{ row: 0 }];
                for (let row = 1; row <= numRows; row++) {
                    let fields = [];
                    this.table_headings.forEach((item) => {
                        let field = this.getField(data.fields, item, row);
                        if (field.data) {
                            this.num_visible_rows = row;
                        }
                        fields.push(field);
                    });
                    rows.push({
                        row: row,
                        fields: fields
                    });
                }
                this.table_rows = rows;
            },
            getVisibleRows: function () {
                let rows = [];
                this.table_rows.forEach((item) => {
                    if (item.row <= this.num_visible_rows) {
                        rows.push(item);
                    }
                });

                return rows;
            },
            getField: function (fields, heading, row) {
                let found = null;
                fields.forEach((item) => {
                    if (item.title == this.table_title + '_' + heading + '_' + row) {
                        found = item;
                    }
                });
                if (!found) {
                    found = {
                        id: heading + '_' + row,
                        type: 'N'
                    }
                }

                return found;
            },
            updateArtwork: function () {
                $('#template-table-fields-modal').modal('hide');
                this.$parent.screenPreview();
            },
            addRow: function () {
                if (!this.artwork.id) {
                    this.table_rows[this.num_visible_rows + 1].fields.forEach((item) => {
                        if (!item.data) {
                            let updateData = {};
                            if (this.$parent.original_field_contents[item.id].data) {
                                updateData.data = this.$parent.original_field_contents[item.id].data;
                                updateData.data2 = this.$parent.original_field_contents[item.id].data2;
                            }
                            this.$parent.updateTemplateField(item, updateData/*, false, true*/);
                        }
                    });
                }
                this.num_visible_rows++;
            },
            deleteRow: function (row) {
                if (row.row < this.num_visible_rows) {
                    for (let r = row.row; r < this.num_visible_rows; r++) {
                        this.table_rows[r].fields.forEach((item) => {
                            let parts = item.title.split('_');
                            let nextItem = this.getField(this.table_rows[r + 1].fields, parts[1], r + 1);
                            let updateData = {
                                data: this.convertData(nextItem.data, nextItem.single_line, item.single_line),
                                data2: this.convertData(nextItem.data2, nextItem.single_line, item.single_line)
                            };
                            this.$parent.updateTemplateField(item, updateData);
                        });
                    }
                    this.count++;
                }
                this.table_rows[this.num_visible_rows].fields.forEach((item) => {
                    let updateData = {
                        data: null,
                        data2: null
                    };
                    this.$parent.updateTemplateField(item, updateData);
                });
                this.num_visible_rows--;
            },
            convertData: function (sourceData, sourceSingleLine, destinationSingleLine) {
                if (!sourceData) {
                    return null;
                }
                if (sourceSingleLine == destinationSingleLine) {
                    return sourceData;
                }
                if (sourceSingleLine) {
                    return '<p>' + sourceData + '</p>';
                }

                return this.stripTags(sourceData);
            },
            stripTags: function (html) {
                if ((html === null) || (html === '')) {
                    return null;
                }
                html = html.toString();

                // Regular expression to identify HTML tags in
                // the input string. Replacing the identified
                // HTML tag with a null string.
                return html.replace(/(<([^>]+)>)/ig, '');
            }
        }
    }
</script>
